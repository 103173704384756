.hrep-accordion {
  .e-accordion {
    background-color: transparent;

    .e-acrdn-item {
      border-left: 0;
      border-right: 0;
      border-top: 0;

      .e-acrdn-header {
        .e-acrdn-header-icon {
          font-size: 1.25rem;
        }

        .e-acrdn-header-content {
          position: relative;
          text-decoration: none;
          padding-right: 3px;
          font-size: .875rem;
          color: $hrep-border;

          .accordion-header-prefix {
            padding-right: 15px;
            margin-right: 15px;
            position: relative;
            border-right: 1px solid #dedede;

            &.no-content {
              margin-right: 2px;
              flex: 0 0 10%;
            }
          }
        }

        .e-toggle-icon {
          display: inline-block;
          right: 0;
        }

        &:hover,
        &:focus {
          .e-acrdn-header-content {
            text-decoration: none;
          }
        }

        .e-badge {
          top: 0;

          &.e-badge-notification {
            font-size: .5rem;
          }
        }
      }

      .e-acrdn-content {
        padding: 0;
      }

      &.e-select.e-expand-state {
        .e-acrdn-header {
          .e-acrdn-header-content {
            color: $hrep-border;
          }
        }
      }
    }

    .e-tgl-collapse-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $hrep-border;

      &:before {
        content: '\e85c';
      }

      &.e-expand-icon {
        transform: rotate(90deg) translateX(-50%);
      }
    }
  }

  &.accordion-primary {
    @include hrep-accordion(40px, 40px, 39px, acc-primary);
  }

  &.accordion-with-list {
    @include hrep-accordion(20px, 20px, inherit, acc-list);

    .hrep-sub-accordion {
      @include hrep-accordion(40px, 40px, 39px, acc-primary);

      .e-accordion {
        .e-acrdn-item {
          &.e-expand-state {
            &.e-select {
              &:not(.e-selected) {
                > .e-acrdn-header {
                  &:focus {
                    background: transparent;
                    border-color: #d6d6d6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.accordion-light {
    .e-accordion {
      .e-acrdn-item {
        border: 1px solid #dddddd;

        .e-acrdn-header {
          background-image: linear-gradient(to top, #efefef, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
          padding: 0 15px 0 30px;

          .e-acrdn-header-content {
            color: #333333;
            font-weight: bold;
          }

          .e-toggle-icon {
            left: 0;
            width: 30px;

            .e-tgl-collapse-icon {
              left: 50%;
              transform: translate(-50%, -50%);

              &.e-expand-icon {
                transform: rotate(90deg) translate(-50%, 50%);
              }
            }
          }
        }

        .e-acrdn-content {
          padding: 15px;
          background-color: $hrep-white;
        }
      }
    }
  }
}