@mixin autofill() {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: $hrep-black;
    -webkit-box-shadow: 0 0 0 40px $hrep-white inset !important;
    background-color: $hrep-white !important;
  }
}

@mixin input-field($height: 40px, $borders: true) {
  input,
  input.e-input {
    height: $height;
    border-color: $hrep-gray;
    color: $hrep-black;
    font-family: Lato, sans-serif;
    @if $borders {
      border-width: 1px;
    } @else {
      border: 0;
    }

    &::placeholder {
      color: $hrep-dark-gray;
    }

    &:-ms-input-placeholder {
      color: $hrep-dark-gray;
    }

    &::-ms-input-placeholder {
      color: $hrep-dark-gray;
    }

    &:hover {
      border-width: 1px;
    }

    &[readonly] {
      background-blend-mode: overlay;
      background-image: linear-gradient(to top, #010202, $hrep-white), linear-gradient(to bottom, #f5f5f5, #f5f5f5);
      color: #76797b;
    }
  }
}

@mixin input-icon($darkType) {
  .e-input-group-icon {
    font-size: 1rem;
    @if $darkType {
      background-color: $hrep-black;
      color: $hrep-white;
      border-color: $hrep-black;
      &:hover {
        background-color: $hrep-black;
        color: $hrep-white;
        border-color: $hrep-black;
      }
    } @else {
      background-color: $hrep-orange;
      color: $hrep-white;
      border-color: $hrep-orange;
      min-height: 1px;
      &:hover {
        background-color: $hrep-orange;
        color: $hrep-white;
        border-color: $hrep-orange;
      }
    }
  }
}

@mixin input-group-field($darkType: false) {
  margin-bottom: 10px;
  border: 0;
  @include input-icon($darkType);

  &.e-input-focus,
  &.e-input-focus:hover {
    &:not(.e-succes):not(.e-warning):not(.e-error):not(.e-disabled) {
      border-color: $hrep-gray;
      box-shadow: none;
    }
  }

  &:not(.e-disabled) {
    @include input-icon($darkType);
  }

  &:not(.e-succes):not(.e-warning):not(.e-error) {
    border-color: $hrep-gray;
    box-shadow: none;
  }

  @include input-field();

  &.control-wrapper {
    &:not(.e-disabled) {
      @include input-icon($darkType);
    }
  }

  &.e-control-wrapper {
    margin-bottom: 10px;
    @include input-field();

    &.e-input-focus,
    &.e-input-focus:hover {
      &:not(.e-succes):not(.e-warning):not(.e-error):not(.e-disabled) {
        border-color: $hrep-gray;
        box-shadow: none;
      }
    }

    &:not(.e-succes):not(.e-warning):not(.e-error) {
      border-color: $hrep-gray;
      box-shadow: none;
    }
  }
  &.e-corner {
    input,
    .e-input,
    .e-input:last-child {
      border-left: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@mixin listItem($titleColor, $subtitleColor, $iconSize, $leftSectionWidth, $leftSectionSize: 0.625rem) {
  .e-listview {
    &.e-list-template {
      .e-list-wrapper {
        &.e-list-multi-line {
          .e-list-item-header {
            color: $titleColor;
            font-size: .875rem;
            font-weight: 900;
            line-height: normal;
          }

          .e-list-content {
            font-size: .75rem;
            color: $subtitleColor;
            line-height: 1;
          }
        }

        .left-section {
          flex: 0 0 $leftSectionWidth;
          font-size: $leftSectionSize;
        }
      }
    }
  }
  .e-content {
    padding-bottom: 16px;
  }

  .e-list-item {
    background: transparent;
    border: 0;
  }

  .right-section {
    .e-btn-icon {
      font-size: $iconSize;
    }
  }
}

@mixin hrep-accordion($accordionHedaerHeight, $accordionHeaderMinHeight, $accordionHeaderLineHeight, $accordionType){
  .e-accordion {
    .e-acrdn-item {
      border: 0;

      &[aria-expanded="true"] {
        .e-acrdn-header,
        .e-acrdn-header:focus {
          background: $hrep-orange;
          border-color: $hrep-orange;

          .e-acrdn-header-content {
            color: $hrep-white;
          }

          > * {
            color: $hrep-white;
          }

          .e-tgl-collapse-icon {
            color: $hrep-white;
          }
        }

        &.e-select.e-expand-state {
          .e-acrdn-header {
            .e-acrdn-header-content {
              color: $hrep-white;
            }
          }
        }
      }

      &[aria-expanded="false"] {
        .e-acrdn-header,
        .e-acrdn-header:focus {
          .e-acrdn-header-content {
            color: #6c7680 !important;
          }

          > * {
            color: #6c7680;
          }

          .e-tgl-collapse-icon {
            color: #6c7680;
          }
        }
      }

      &.e-select.e-expand-state {
        .e-acrdn-header {
          .e-acrdn-header-content {
            color: #6c7680;
          }
        }
      }

      .e-acrdn-header {
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        background-image: linear-gradient(to top, #efefef, #ffffff), linear-gradient(to bottom, #ffffff, #ffffff);
        height: $accordionHedaerHeight;
        min-height: $accordionHeaderMinHeight;
        line-height: $accordionHeaderLineHeight;
        padding: 0 30px 0 15px;

        .e-toggle-icon {
          height: $accordionHedaerHeight;
          min-height: $accordionHeaderMinHeight;
        }

        .e-acrdn-header-content {
          display: flex;
          color: #6c7680;
        }
      }

      .e-acrdn-panel {
        margin-top: 5px;
        border-radius: 4px;
        @if($accordionType == acc-primary){
          border: 1px solid #cccccc;
        } @else {
          border: 0;
          margin-top: 0;
        }
      }

      .e-acrdn-content {
        color: #a6a6a6;
        line-height: 1.3;
        @if($accordionType == acc-primary){
          padding: 12px 26px 8px 18px;
          background-blend-mode: overlay;
          background-image: linear-gradient(to top, #010202, $hrep-white), linear-gradient(to bottom, $hrep-white, $hrep-white);
        } @else {
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}


@mixin hrep-primary-button() {
  background-color: $hrep-orange;
  border-color: $hrep-orange;
  //min-height: 40px;

  &:hover,
  &:focus,
  &:active {
    background-color: $hrep-orange;
    border-color: $hrep-orange;
  }
  &:disabled {
    background-color: transparentize($hrep-orange, .2);
    border-color: $hrep-orange;
    color: $hrep-white;
  }
}
