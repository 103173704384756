.e-card {
  border-radius: 10px;
  border: 0;
  padding: 10px;

  .e-card-actions {
    position: absolute;
    top: 0;
    right: 12px;

    .e-card-btn {
      background: transparent;
      border: 0;
      color: $hrep-black;
      padding: 0;

      &:hover {
        background: transparent;
        border: 0;
        color: $hrep-black;
      }
    }
  }

  .e-avatar {
    &.e-avatar-xlarge {
      width: 4em;
      height: 4em;
    }
  }

  .e-card-header {
    padding: 0;

    .e-card-header-caption {
      .e-card-header-title {
        font-size: 1.25rem;
        font-weight: bold;
        color: $hrep-border;
        line-height: 0.83;
      }

      .e-card-sub-title {
        font-size: .875rem;
        color: $hrep-border;
        line-height: 0.83;
      }
    }
  }
}