@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Light/Lato-Light.woff2') format('woff2'),
  url('../assets/fonts/Light/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Bold/Lato-Bold.woff2') format('woff2'),
  url('../assets/fonts/Bold/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Black/Lato-Black.woff2') format('woff2'),
  url('../assets/fonts/Black/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Regular/Lato-Regular.woff2') format('woff2'),
  url('../assets/fonts/Regular/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

