/* You can add global styles to this file, and also import other style files */
@import "css/base.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-lists/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap.css';
