@import "mixins";
@import "variables";
@import "fonts";
@import "typography";
@import "icons";
@import "buttons";
@import "inputs";
@import "dialogs";
@import "accordions";
@import "cards";
@import "lists";
@import "tables";

html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

html body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.gray-container {
  padding-bottom: 100px;
  background: linear-gradient(120deg, $hrep-light-gray 70%, $hrep-white 30%);
  @media (max-width: 768px) {
    background: $hrep-light-gray;
  }
}

.bg-dark-orange {
  background-color: $hrep-dark-orange;
}

.h-100 {
  height: 100%;
}

hr.hrep-hr {
  box-shadow: 0px -1px 0 0 rgb(75 77 82 / 20%);
  background-color: $hrep-white;
  opacity: 1;
}

.logo {
  margin-bottom: 50px;
  &.logo-small {
    margin-top: 100px;
    margin-bottom: 25px;
  }
}

.hrep-card {
  background-color: $hrep-orange;
  border-radius: 10px;
  padding: 10px;
  margin: 11px 0;

  .e-input-group {
    @include input-group-field($darkType: true);
  }

  a {
    color: $hrep-white;

    &:hover {
      color: $hrep-white;
    }
  }
  .card-left-side {
    flex: 0 0 20%;
  }
}

.hrep-menu {
  background-color: $hrep-orange;
  margin-bottom: 20px;
}

// Popover on left section
.hrep-popover {
  background-color: $hrep-white;
  color: $hrep-black;
  position: relative;

  .popover-arrow {
    position: absolute;
    top: 10px;
    left: -.5rem;

    &:before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: .5rem .5rem .5rem 0;
      border-right-color: $hrep-white;
    }
  }

  .popover-body {
    padding: 5px;
    color: $hrep-black;
  }
}

// Error message next to field
.hrep-error-msg {
  padding: 0 10px;
  background-color: #e83851;
  color: $hrep-white;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
  min-height: 25px;
  overflow: hidden;

  span {
    &:last-child {
      margin-left: 4px;
    }
  }
}

// Chips
.e-chip-list {
  .e-chip,
  .e-chip:hover {
    background: #cccccc;
    color: $hrep-white;
    border: 0;
    font-size: .75rem;
    height: 20px;

    .e-chip-delete.e-dlt-btn {
      color: $hrep-white;
    }
  }

  &.e-chip,
  &.e-chip:hover {
    background: #cccccc;
    color: $hrep-white;
    border: 0;
    font-size: .75rem;
    height: 20px;

    .e-chip-delete.e-dlt-btn {
      color: $hrep-white;
    }
  }
}

//Badges
.e-badge {
  &.e-badge-success {
    &:not(.e-badge-ghost) {
      &:not([href]) {
        background-color: $hrep-green;
      }
    }

    &[href] {
      &:not(.e-badge-ghost) {
        background-color: $hrep-green;
      }
    }
  }
}

// Dividers
.hrep-divider {
  width: 100%;
  height: 1px;
  box-shadow: 0px -1px 0 0 rgba(75, 77, 82, 0.2);
  background-color: $hrep-white;
}

// Search page
#search-inputs {
  .hrep-input {
    &.e-input-group.e-control-wrapper {
      box-shadow: inset 1px 1.7px 4px 0 rgba(2, 3, 3, 0.15);
      border-color: #c6cccc;

      .e-input {
        color: #939ca1;
      }
    }
  }
}

// File uploader
.hrep-file-upload {
  .e-upload {
    border: 0;

    .e-file-select-wrap {
      padding: 0;

      .e-btn {
        background-color: transparent;
        border: 0;

        &:hover,
        &:active,
        &:focus {
          background: transparent;
        }
      }
    }

    .e-upload-files {
      .e-upload-file-list {
        min-height: auto;
        padding: .5rem 0;
      }
    }

    .e-upload-actions {
      text-align: center;

      .e-file-upload-btn {
        min-height: auto;
      }
    }
  }

  input.hrep-file-upload {
    height: auto;
  }
}

// Footer
footer {
  background-color: $hrep-border;
  color: $hrep-white;
  padding: 15px 0;
  @media (max-width: 768px) {
    background: $hrep-light-gray;
  }

  a {
    &:hover,
    &:focus,
    &:active {
      color: $hrep-orange;
    }
  }
}

// Dashboard
.w-60 {
  width: 60%;
}
