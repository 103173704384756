//Input field
@include autofill();
input,
input.e-input {
  height: 40px;
  border-color: $hrep-gray;
  color: $hrep-black;
  margin-bottom: 16px;
  font-family: Lato, sans-serif;

  &:hover {
    &:not(.e-succes):not(.e-error):not(.e-warning):not([disabled]):not(:focus) {
      border-color: $hrep-gray;
    }
  }

  &.no-side-borders {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

// Input group field
.e-input-group {
  @include input-group-field();
}

.hrep-input {
  &.e-input-group.e-control-wrapper {
    margin-bottom: 0;
    @include input-field($height: 25px, $borders: false);

    .e-input-group-icon {
      min-height: 1px;
    }
  }
}

//Dropdown-list
.hrep-dropdown {
  &.no-margin-dropdown {
    &.e-input-group.e-control-wrapper {
      margin-top: 0;
    }
  }

  &.e-input-group {
    &.e-control-wrapper {
      margin-bottom: 0;
      @include input-field($height: 30px, $borders: false);
    }

    .e-input-group-icon {
      font-size: .625rem;
      background-color: transparent;
      color: $hrep-black;
      min-height: 1px;
    }
  }

  &.suffix-filled {
    .e-input-group-icon {
      background-color: $hrep-orange;
      color: $hrep-white;

      &:hover {
        background-color: $hrep-orange;
        color: $hrep-white;
      }
    }
    &.e-input-group:not(:disabled) {
      .e-ddl-icon {
        &:hover {
          background-color: $hrep-orange;
          color: $hrep-white;
        }
      }
    }
  }
}

// Radio button
.e-radio {
  &:checked {
    + label {
      &:before {
        border-color: $hrep-orange;
      }

      &:after {
        color: $hrep-orange;
        background-color: $hrep-orange;
      }

      &:focus {
        &:before {
          box-shadow: 0 0 4px 0 $hrep-orange;
        }
      }

      &:hover {
        &:before {
          border-color: $hrep-orange;
        }

        &:after {
          color: $hrep-orange;
          background-color: $hrep-orange;
        }
      }
    }

    &:focus {
      + label {
        &:after {
          color: $hrep-orange;
          background-color: $hrep-orange;
        }
      }
    }
  }

  &:focus {
    + label {
      &:before {
        box-shadow: 0 0 4px 0 $hrep-orange;
      }

      &:after {
        color: $hrep-orange;
        background-color: $hrep-orange;
      }
    }
  }
}

// Date field
.hrep-datepicker {
  &.no-calendar-icon {
    .e-date-icon {
      display: none;
    }
  }
}

