.hrep-list {
  .e-listview {
    font-family: Lato, sans-serif;
    .e-list-item {
      background-color: transparent;
      color: $hrep-border;
      background-image: linear-gradient(to top, rgba(215, 215, 215, 0.45), $hrep-white);
    }

    .e-list-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(.e-list-multiline) {
        padding-right: 20px;
      }

      .left-section {
        flex: 0 0 30%;

        &.icon-section {
          flex: 0 0 30px;
        }
      }

      .main-section {
        flex: 1;
        padding-right: 10px;
      }

      .e-badge {
        position: relative;
        left: 0;
        top: 0;
        width: .75rem;
        height: .75rem;
      }
    }
  }

  &.hrep-search-list {
    @include listItem($hrep-orange, #181817, 1rem, 5%,);
  }

  &.hrep-messages-list {
    @include listItem($hrep-orange, #666666, .75rem, 10%,);

    .e-listview {
      .e-list-wrapper {
        display: block;
      }
    }

    .list-left-section {
      font-size: .625rem;
    }

    .list-main-section {
      .e-list-content {
        color: #666666;
      }

      button {
        border-radius: 15px;
      }
    }
  }
}

.hrep-list-link {
  &.e-listview {
    .e-list-item {
      background: transparent;
      border: 0;

      a {
        color: $hrep-border;
        span {
          &:last-child {
            font-size: 0.75rem;
          }
          &.e-badge-notification {
            font-size: .5rem;
          }
        }
      }

      .e-list-item-content {
        flex: 1;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $hrep-white;
        box-shadow: 0px -1px 0 0 rgba(75, 77, 82, 0.2);
      }

      &.e-active {
        background: $hrep-orange;

        a {
          color: $hrep-white;
        }
        .e-list-icon {
          color: $hrep-white;
        }
      }
    }
    .e-list-icon {
      height: auto;
      font-size: 1rem;
      color: $hrep-orange;
      &.icon-big {
        font-size: 1.25rem;
      }
    }
  }
}