// Simple icons
@import "~simple-line-icons/scss/simple-line-icons";

// E icons
.e-icons {
  &.e-icon-tick {
    &:before {
      content: '\e614';
    }
  }
  &.e-date-icon {
    &:before {
      content: '\e243';
    }
  }
}

// General style for icons
.hrep-squared-icon {
  border: 1px solid $hrep-orange;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background-color: $hrep-white;
  color: $hrep-orange;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
