h1, h2 {
  color: $hrep-orange;
}

h1, h2, h3, h4, h5, h6, p, a, label {
  font-family: Lato, sans-serif;
}

.hrep-txt-white {
  color: $hrep-white;
}
.hrep-txt-primary {
  color: $hrep-orange;
}
.hrep-txt-dark {
  color: $hrep-black;
}