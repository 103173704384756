.e-dialog {
  .e-dlg-header-content {
    padding: 10px;
    background-color: $hrep-light-gray;
  }

  .e-icon-dlg-close {
    color: $hrep-black;
  }

  .e-dlg-content {
    .content {
      min-height: 100%;
      background-color: $hrep-white;
      //text-align: center;
    }
  }

  &.hrep-primary-dialog {
    .e-dlg-header-content {
      background-color: $hrep-orange;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;

      .e-btn.e-flat {
        &:hover {
          color: $hrep-white;
        }
      }

      .e-dlg-closeicon-btn {
        line-height: 1;
        height: auto;
        bottom: 0;
        padding: 0;

        .e-btn-icon {
          margin-top: 0;
        }
      }
    }

    .e-dlg-header {
      width: auto;
      color: $hrep-white;
      font-size: .875rem;

      * {
        color: $hrep-white;
        font-size: .875rem;
      }
    }

    .e-icon-dlg-close {
      color: $hrep-white;

      &:hover {
        color: $hrep-white;
      }
    }
  }
}

// DV modal
.hrep-dv-number {
  background-color: $hrep-orange;
  color: $hrep-white;
  font-weight: bold;
  max-width: 120px;
  padding-top: 5px;
  padding-bottom: 5px;

  span {
    padding-top: 5px;
    padding-bottom: 5px;

    &:first-child {
      border-right: 1px solid $hrep-white;
    }
  }
}

.hrcp-dv {
  background-color: $hrep-orange;
  color: $hrep-white;
  font-weight: bold;
  max-width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 5px;

  span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
